.Artist-list {
    width: 33%;
    max-height: 950px;
    overflow-y: scroll;
    padding: .88rem;
    background-color: rgba(1, 12, 63, 0.7);
    box-shadow: 0 4px 2px 2px #000000;
  }
  
.Artist-list h1 {
    width: 100%;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #6f6f6f;
    font-family: 'Poppins', sans-serif;
    font-size: 1.55rem;
    color: #fff;
    margin-bottom: 1rem;
  }

@media only screen and (max-width: 1020px) {
    .Artist-list {
      width: 90%;
      margin-bottom: 2rem;
    }
  }